<template>
  <div class="home">
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      :interval="3000"
      indicators
      controls
      label-next=""
      label-prev=""
      background="#7b368b"
      img-width="1024"
      img-height="480"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->
      <b-carousel-slide v-for="(slide) in list_image" :key="slide.id">
        <template #img>
          <img
            class="d-block img-fluid w-100"
            style="width:1024;height:480"
            :src="'data:image/jpeg;base64,'+slide.image"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
      <!-- <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            style="width:1024;height:480"
            src="img/slide2.jpg"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>

      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            style="width:1024;height:480"
            src="img/slide3.jpg"
            alt="image slot"
          >
        </template>
      </b-carousel-slide> -->

      <!-- Slide with blank fluid image to maintain slide aspect ratio -->
      
    </b-carousel>
    <div class="div_content1">
      
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="4" style="display:flex">
            <div style="margin-right:10px">
              <label class="text-42 text-oswald text-bold" style="color:#7dbbf0">+</label>
              <label class="text-42 text-oswald text-bold">1200</label>
            </div>
            <div style="margin-top:9px">
              <label class="text-14 text-monts" >DONATION</label><br>
              <label class="text-14 text-monts text-bold">AL-QURAN</label>
            </div>
          </b-col>
          <b-col sm="4" style="display:flex;justify-content: center;">
            <div style="margin-right:10px;">
              <label class="text-42 text-oswald text-bold" style="color:#7dbbf0">+</label>
              <label class="text-42 text-oswald text-bold">150</label>
            </div>
            <div style="margin-top:9px">
              <label class="text-14 text-monts" >HAPPY</label><br>
              <label class="text-14 text-monts text-bold">STUDENTS</label>
            </div>
          </b-col>
          <b-col sm="4" style="display:flex;justify-content: right;">
            <div style="margin-right:10px">
              <label class="text-42 text-oswald text-bold" style="color:#7dbbf0">+</label>
              <label class="text-42 text-oswald text-bold">10</label>
            </div>
            <div style="margin-top:9px">
              <label class="text-14 text-monts" >PRODUCT</label><br>
              <label class="text-14 text-monts text-bold">VARIANT</label>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="div_content2">
      <label class="text-krub text-12 vertical-left" style="color:#fff">#BELAJARBARENG</label>
      <label class="text-krub text-12 vertical-right" style="color:#fff">#BELAJARBARENG</label>
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12" style="text-align:center;margin-bottom:85px;padding-top: 20px;padding-bottom: 70px;position: relative;display: block;">
            <img src="../assets/oval4.png" class="image-oval4">
            <label class="text-krub text-bold text-60" style="color:#fff;margin-top:100px">+1200</label><br>
            <label class="text-60 text-krub-reg" style="margin-left:15px;color:#fff">DONATION</label><br>
            <label class="text-krub text-bold text-60" style="color:#fff;">AL-QURAN</label>
          </b-col>
          <b-col sm="12">
            <b-row align-h="center">
              <b-col cols="8">
                <label class="text-krub-reg text-12" style="text-align:center;color:#fff">
                  Kami juga mengadakan berbagai acara dan event terkait buku Islam yang menarik. Dari diskusi buku hingga seminar dan lokakarya, kami berusaha untuk menciptakan ruang bagi para pembaca untuk berbagi pemikiran, belajar, dan berinteraksi dengan penulis dan cendekiawan Islam terkemuka. Tetaplah update dengan jadwal acara kami dan ikuti kegiatan yang memperkaya pemahaman Anda tentang Islam.
                </label>
              </b-col>
            </b-row>
          </b-col>          
        </b-row>
      </b-container>
      <b-row style="margin-left:0px;margin-right:0px">
        <b-col sm="6" style="padding-left:60px;">
          <label class="text-krub text-60" style="color:#000">HOME</label>
        </b-col>
        <b-col sm="6" style="text-align:right;padding-right:20px;" >
          <label class="text-krub text-60" style="color:#000">CATALOG</label>
        </b-col>
      </b-row>
      
    </div>
    <div class="div_content5">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12">
            <b-row>
              <b-col sm="6">
                <label class="text-krub text-bold text-60" style="color:#fff">FIND</label>
            <label class="text-24 text-krub-reg" style="margin-left:15px;color:#c5c178">Your Book</label>
              </b-col>
              <b-col sm="6" style="text-align:right">
                <label class="text-14 text-krub" style="margin-top:25px;color:#fff">EDITION</label><br>
                <label class="text-14 text-krub" style="color:#fff">2023</label>
              </b-col>
            </b-row>
            
          </b-col>
         
          <b-col sm="12" style="margin-bottom:25px">
            <b-row>
              <b-col sm="3" v-for="(produk,index) in list_produk" :key="produk.id">
                <div class="border-outline">
                  <div class="number-book">
                    <label class="text-krub">{{index+1+offset}}</label>
                  </div>
                  <img :src="'data:image/png;base64,'+produk.foto" class="image-book" style="width:100%" @click="openImage(produk.foto)">
                </div>
                <b-button class="button-biru text-krub" @click="gotoBeli(produk.link_tokped,produk.link_shopee)">BELI YUK</b-button>
              </b-col>
              <b-col sm="12" style="text-align:center">
                  <div @click="gotoProduk()" style="cursor:pointer">
                    <label class="text-krub text-14" style="color:#fff;margin-top:25px;margin-right:25px;cursor:pointer">MORE PRODUCT</label>
                    <img src="../assets/arrow-right.png" style="width:50px;cursor:pointer">
                  </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col sm="12" style="margin-bottom:45px">
            <b-button variant="danger">Daftar Sekarang</b-button>
          </b-col> -->
        </b-row>
      </b-container>
    </div>
    <div class="div_content3">
      <img src="../assets/oval1.png" class="oval-bg1">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12">
            <b-row>
              <b-col sm="6">
                <label class="text-krub text-bold text-60" style="color:#fff;margin-top:100px">About</label>
                <label class="text-24 text-krub-reg" style="margin-left:15px;color:#c5c178">US .</label>
                <label class="text-krub-reg" style="color:#fff;text-align:justify;margin-bottom:40px">
                  <span v-html="about_us.substr(0,550)"></span>
                </label>
                <b-button class="button-biru-left text-krub" @click="gotoProfile()">READ MORE</b-button>
              </b-col>
              <b-col sm="6" class="image-person1" >
                <img src="../assets/person1.png" style="width:60%">
              </b-col>
            </b-row>
            
          </b-col>
          
        </b-row>
      </b-container>
    </div>
    <div class="div_content4">
      <label class="text-krub text-60 vertical-right2" style="color:#000;right:0px">SERVICE</label>
      <img src="../assets/oval2.png" class="oval-bg2">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12">
            <b-row>
              <b-col sm="6" class="image-person1" >
                <img src="../assets/person2.png" style="width:100%" >
              </b-col>
              <b-col sm="6" >
                <label class="text-krub text-bold text-60" style="color:#fff;margin-top:100px">Why</label>
                <label class="text-24 text-krub-reg" style="margin-left:15px;color:#c5c178">US .</label>
                <label class="text-krub-reg" style="color:#fff;text-align:justify;margin-bottom:40px">
                  <span v-html="why_us.substr(0,550)"></span>
                </label>
                <b-button class="button-biru-left text-krub" @click="gotoProfile()">READ MORE</b-button>
              </b-col>
              
            </b-row>
            
          </b-col>
          
        </b-row>
      </b-container>
    </div>
    <div class="div_content4">
      <div>
        <label class="text-krub text-12 vertical-left2" style="color:#fff">READY TO</label><br>
        <label class="text-krub text-12 vertical-left2" style="color:#fff;left:20px">GET STARTED</label>
        <img src="../assets/arrow-left.png" class="arrow-vertical-left">
      </div>
      <div>
        <label class="text-krub text-12 vertical-right2" style="color:#fff;right:20px">READY TO</label><br>
        <label class="text-krub text-12 vertical-right2" style="color:#fff;">GET STARTED</label>
        <img src="../assets/arrow-left.png" class="arrow-vertical-right">
      </div>
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12" style="justify-content:center">
            
              <iframe width="100%" height="680" :src="youtube_embed" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </b-col>
          
        </b-row>
      </b-container>
    </div>
    <div class="div_content5">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12" style="text-align:center;margin-bottom:85px">
            <label class="text-krub text-bold text-60" style="color:#fff;margin-top:100px">What</label><br>
            <label class="text-24 text-krub-reg" style="margin-left:15px;color:#c5c178">users think .</label>
          </b-col>
          <b-col sm="12">
            <b-row>
              <b-col sm="4" v-for="(testi) in list_testimoni" :key="testi.id">
                <div style="width:100%;text-align:center">
                  <img src="../assets/user-img.png" style="width:120px" v-show="testi.foto == '' || testi.foto == null">
                  <div>
                    <span class="fa fa-star checked" v-if="testi.rating >= 1"></span>
                    <span class="fa fa-star" v-if="testi.rating < 1"></span>
                    <span class="fa fa-star checked" v-if="testi.rating >= 2"></span>
                    <span class="fa fa-star" v-if="testi.rating < 2"></span>
                    <span class="fa fa-star checked" v-if="testi.rating >= 3"></span>
                    <span class="fa fa-star" v-if="testi.rating < 3"></span>
                    <span class="fa fa-star checked" v-if="testi.rating >= 4"></span>
                    <span class="fa fa-star" v-if="testi.rating < 4"></span>
                    <span class="fa fa-star checked" v-if="testi.rating >= 5"></span>
                    <span class="fa fa-star" v-if="testi.rating < 5"></span>
                  </div>
                </div>
                <div style="text-align:center;margin-top:15px">
                  <label class="text-krub" style="color:#aeaeae">{{testi.name}}</label>
                </div>
                <div style="text-align:center;margin-top:15px">
                  <label class="text-krub" style="color:#a197a6">"{{testi.comment}}"</label>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <div style="display:flex;justify-content:center;margin-top:35px">
                <!-- <label class="text-krub text-14" style="color:#fff;margin-top:25px;margin-right:25px;cursor:pointer">MORE PRODUCT</label> -->
                <div @click="gotoPrev()" >
                    <img src="../assets/arrow-left.png" style="width:50px;cursor:pointer;margin-right: 55px;">
                </div>
                <!-- <div class="currentNumber">
                    <label class="text-krub text-14" style="color:#fff">{{curentPage}}</label>
                </div> -->
                <div @click="gotoNext()">
                    <img src="../assets/arrow-right.png" style="width:50px;cursor:pointer">
                </div>
              </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="div_content6">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12" >
            
            <b-row>
              <b-col sm="6" class="pertantaan-div">
                <img src="../assets/person3.png" class="person3-img">
              </b-col>
              <b-col sm="6" style="text-align:center">
                <b-row>
                  <b-col sm="12">
                    <label class="text-krub text-bold text-60" 
                    style="color:#fff;margin-top:100px">PERTANYAAN</label><br>
                    <label class="text-42 text-krub-reg" style="margin-left:15px;color:#c5c178">isi formulir berikut</label><br>
                    <label class="text-14 text-krub" style="color:#fff;text-align:left">
                      Kami senang mendengar tanggapan, pertanyaan, atau masukan dari Anda. Jika Anda memiliki pertanyaan tentang produk atau pelayanan kami, silakan hubungi tim layanan pelanggan kami melalui formulir kontak yang tersedia di halaman ini.
                    </label>
                  </b-col>
                  <b-col sm="12">
                    <input type="text" class="form-control form-pertanyaan" v-model="name" placeholder="NAME">
                    <input type="text" class="form-control form-pertanyaan" v-model="email" placeholder="MAIL">
                    <!-- <input type="text" class="form-control form-pertanyaan" placeholder="MESSAGE"> -->
                    <textarea class="form-control form-pertanyaan" rows="7" v-model="pesan" placeholder="MESSAGE"></textarea>
                    <b-button class="button-biru text-krub" style="margin-top:45px" @click="sendPesan()">SEND MESSAGE</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          
        </b-row>
      </b-container>
      <b-modal id="modal-olshop" size="sm" centered >
        <div class="w-100" style="text-align:center">
            <a :href="link_shopee" target="_blank">
                <img src="../assets/shopee-icon.png" class="pointer-select" style="width:80px;margin-right:15px" >
            </a>
            <a :href="link_tokped" target="_blank">
                <img src="../assets/tokped-icon.png" class="pointer-select" style="width:80px;margin-right:15px" >
            </a>
        </div>
        <template #modal-footer>
            <div class="w-100">
                <b-button @click="closeModal()" class="button-biru text-krub">Tutup</b-button>
            </div>
        </template>
    </b-modal>
    </div>
    <Footer />
    <b-modal id="modal-image" title="BootstrapVue" hide-footer hide-header>
      <img :src="'data:image/png;base64,'+zoomImage" class="image-book" style="width:100%">
    </b-modal>
  </div>
  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Footer from '@/views/Footer.vue'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
export default {
  name: 'Home',
  components: {
    Footer
    // HelloWorld
  },
  data() {
      return {
        slide: 0,
        sliding: null,
        list_produk: [],
        list_testimoni:[],
        list_image:[],
        offset:0,
        offsetT:0,
        perpage:4,
        perpageT:3,
        curentPage:1,
        curentPageT:1,
        link_tokped:'',
        link_shopee:'',
        contact_tokped:'',
        contact_shopee:'',
        link_fb:'',
        link_ig:'',
        link_youtube:'',
        youtube_embed:'',
        name:'',
        email:'',
        pesan:'',
        addMail:'',
        about_us: null,
        why_us: null,
        zoomImage:''
      }
    },
    mounted(){
        const vm = this;
        const req_post = {
            perpage:this.perpage,
            offset:this.offset
        }
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'list_catalog',
            headers: { 'content-type': 'application/json' },
            data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.list_produk = result.produk;
            }
        });

        const req_testi = {
            perpage:this.perpageT,
            offset:this.offsetT
        }
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'list_testimoni',
            headers: { 'content-type': 'application/json' },
            data: JSON.stringify(req_testi),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.list_testimoni = result.testimoni;
            }
        });

        Vue.axios({
            method: 'get',
            url: this.$urlApi+'company_profile',
            headers: { 'content-type': 'application/json' },
            // data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.about_us = result.about_us;
                vm.why_us = result.why_us;
            }
        });
        Vue.axios({
            method: 'get',
            url: this.$urlApi+'company_info',
            headers: { 'content-type': 'application/json' },
            // data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.link_fb = result.link_fb;
                vm.link_ig = result.link_ig;
                vm.link_youtube = result.link_youtube;
                vm.contact_shopee = result.link_shopee;
                vm.contact_tokped = result.link_tokped;
                vm.youtube_embed = result.youtube_embed;
            }
        });
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'list_slide',
            headers: { 'content-type': 'application/json' },
            // data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.list_image = result.list_image;
            }
        });
    },
    methods: {
      sendPesan()
      {
        // const vm = this;
        var check_mail = this.validateEmail(this.email)
        if(check_mail == true){
          const req_post = {
              name:this.name,
              email:this.email,
              pesan:this.pesan
          }
          Vue.axios({
              method: 'post',
              url: this.$urlApi+'add_pertanyaan',
              headers: { 'content-type': 'application/x-www-form-urlencoded' },
              data: JSON.stringify(req_post),
          }).then(function (response) {
              var result = response.data;
              console.log(result);
              // if(result.error != true)
              if(result.status == true)
              {
                  
                  alert(result.msg)
              }
              else
              {
                  alert(result.msg)
              }
          });
        }
        else{
          alert('Email yang ada masukan tidak valid')
        }
      },
      gotoProfile()
      {
        // this.$router.push({ path: '/profile'}); 
        window.location.href = 'profile'
      },
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      },
      gotoProduk(){
        window.location.href = 'catalog'
      },
      gotoBeli(link_tokped,link_shopee){
        this.$bvModal.show('modal-olshop')
        this.link_tokped = link_tokped;
        this.link_shopee = link_shopee;
      },
      openImage(value){
        this.zoomImage = value;
        this.$bvModal.show('modal-image')
      },
      closeModal()
      {
        this.$bvModal.hide('modal-olshop')
      },
      gotoPrev(){
        if(this.curentPageT > 1)
        {
          const vm = this;
          const req_testi = {
              perpage:this.perpageT,
              offset:this.offsetT
          }
          Vue.axios({
              method: 'post',
              url: this.$urlApi+'list_testimoni',
              headers: { 'content-type': 'application/json' },
              data: JSON.stringify(req_testi),
          }).then(function (response) {
              var result = response.data;
              console.log(result);
              // if(result.error != true)
              if(result.status == 'Success')
              {
                  vm.offsetT = vm.offsetT-3;
                  vm.curentPageT = vm.curentPageT--;
                  vm.list_testimoni = result.testimoni;
              }
          });
        }
        
      },
      gotoNext(){
        const vm = this;
        const req_testi = {
            perpage:this.perpageT,
            offset:this.offsetT+3
        }
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'list_testimoni',
            headers: { 'content-type': 'application/json' },
            data: JSON.stringify(req_testi),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
              if(result.testimoni.lenght > 0)
              {
                vm.offsetT = vm.offsetT+3;
                vm.curentPageT = vm.curentPageT++;
                vm.list_testimoni = result.testimoni;
              }
                
            }
        });
      },
      validateEmail(mail){
            if (/^[^@]+@\w+(\.\w+)+\w$/.test(mail))
            {
                return (true)
            }
                //alert("You have entered an invalid email address!")
                return (false)
        }
    }
}
</script>
<style>
@font-face {
   font-family: myFirstFont;
   src: url("../assets/font/BStyle_B.ttf");
}
@font-face {
   font-family: Montserrat;
   src: url("../assets/font/Montserrat-VariableFont_wght.ttf");
}
@font-face {
   font-family: Oswald;
   src: url("../assets/font/Oswald-VariableFont_wght.ttf");
}
@font-face {
   font-family: Krub;
   src: url("../assets/font/Krub-Bold.ttf");
}
@font-face {
   font-family: KrubReg;
   src: url("../assets/font/Krub-Regular.ttf");
}
.checked {
  color: orange;
}
.pointer-select
{
  cursor: pointer;
}
.arrow-vertical-left
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(270deg);
  top: 40%;
  position: absolute;
}
.arrow-vertical-right
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(90deg);
  top: 55%;
  position: absolute;
  right: 0;
}
.vertical-left
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 50%;
  position: absolute;
}
.vertical-left2
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 50%;
  position: absolute;
}
.vertical-right
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 30%;
  right: 0;
  position: absolute;
}
.vertical-right2
{
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform:rotate(180deg);
  top: 40%;
  right: 0;
  position: absolute;
}
.form-pertanyaan
{
  margin-top: 25px;
  background-color: transparent !important;
  border: 2px solid #9172d1 !important;
  border-radius: 0 !important;
  color:#fff !important;
}
.form-newsletter
{
  margin-top: 25px;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  border-radius: 0 !important;
  color:#000 !important;
}
/* .person3-img
{
  width: 600px;
  position: absolute;
} */
.pertantaan-div
{
  display: block;
  position: relative;
  height: 400px;
}
.pertantaan-div img
{
  width: 95%;
  position: absolute;
}
.image-person1 img
{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.oval-bg1
{
  position: absolute;
}
.oval-bg2
{
  margin-top: -200px;
  position: absolute;
  right: 0;
}
.button-biru
{
  background-color: #8eb0ff !important;
  font-family: Krub !important;
  font-size: 12px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
}
.button-biru-left
{
  background-color: #8eb0ff !important;
  font-family: Krub !important;
  font-size: 12px !important;
}
.border-outline
{
  border:1px solid #fff;
  padding:25px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.number-book
{
  position: absolute;
  background-color:#fff ;
  padding:10px;
  margin-top: -25px;
  margin-left: -25px;
}
.border-outline img{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text-h{
  font-family: myFirstFont;
  font-size: 40px;
}
.text-krub{
  font-family: Krub;
}
.text-krub-reg{
  font-family: KrubReg;
}
.text-oswald{
  font-family: Oswald;
}
.text-monts{
  font-family: Montserrat;
}
.text-42{
  font-size: 42px;
}
.text-60{
  font-size: 60px;
}
.text-14{
  font-size: 14px;
}
.text-24{
  font-size: 24px;
}
.text-bold
{
  font-weight: bolder;
}
.text-m{
  font-size: 20px;
}
.div_content1
{
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #000;
    color: #fff;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)); */

}
.div_content2
{
    padding-top: 120px;
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    position: relative;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content3
{
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content4
{
    padding-top: 150px;
    padding-bottom: 50px;
    background-color: #7b368b;
    position: relative;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content5
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.8); */
    /* background: linear-gradient(to top, #7b368b, #7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,#7b368b,#7b368b,
    #7b368b,#7b368b,#7b368b,#7b368b,rgba(0, 0, 0, 1)); */
}
.div_content6
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #7b368b;
    color: #000;
    /* height: 850px; */
}
.div_content7
{
    /* padding-top: 50px; */
    padding-bottom: 50px;
    background-color: #000;
    color: #fff;
    /* height: 850px; */
}
.image-oval4
{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 100%;
}
@media only screen and (max-width: 600px) {
  .image-oval4
  {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
  }
  .vertical-left2
  {
    display: none;
  }
  .vertical-right2
  {
    display: none;
  }
}
</style>