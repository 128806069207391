<template>
    <div class="div_content7">
      <b-container class="bv-example-row" >
        <b-row >
          <b-col sm="12" style="margin-top:100px" >
            <b-row>
              <b-col sm="6">
                <label class="text-krub text-bold text-24" 
                style="color:#fff;margin-bottom:15px;margin-top:15px;border-bottom:1px solid #fff">About us</label><br>
                <!-- <label class="text-krub-reg text-bold text-14">Home</label><br>
                <label class="text-krub-reg text-bold text-14">Our Event</label><br>
                <label class="text-krub-reg text-bold text-14">Our Profile</label><br>
                <label class="text-krub-reg text-bold text-14">Our Catalog</label><br>
                <label class="text-krub-reg text-bold text-14">Our Contact</label><br> -->
                <router-link class="header-menu text-header" to="/">Home</router-link><br>
                <router-link class="header-menu text-header" to="/event">Our Event</router-link><br>
                <router-link class="header-menu text-header" to="/catalog">Our Catalog</router-link><br>
                <router-link class="header-menu text-header" to="/profile">Our Profile</router-link><br>
                <router-link class="header-menu text-header" to="/contact">Our Contact</router-link><br>
                <!-- <label class="text-krub-reg text-bold text-14">Privacy Policy</label><br> -->

              </b-col>
              <!-- <b-col sm="3">
                <label class="text-krub text-bold text-24" 
                style="color:#fff;margin-bottom:15px;margin-top:15px;border-bottom:1px solid #fff">What we do</label><br>
                <label class="text-krub-reg text-bold text-14">News and stories</label><br>
                <label class="text-krub-reg text-bold text-14">Publication</label><br>
                <label class="text-krub-reg text-bold text-14">Take Action</label><br>
                <label class="text-krub-reg text-bold text-14">Recomendations</label><br>
                <label class="text-krub-reg text-bold text-14">Help</label><br>

              </b-col> -->
              <b-col sm="6">
                  <b-row>
                    <b-col sm="12" style="text-align:right">
                      <label class="text-krub text-bold text-24" 
                      style="color:#fff;">Sign Up to receive</label><br>
                      <label class="text-krub text-bold text-24" 
                      style="color:#fff;">Our Newsletter</label><br>
                    </b-col>
                    <b-col sm="6">
                    </b-col>
                    <b-col sm="6">
                        <form v-on:submit.prevent="sendNewsletter">
                            <input type="text" class="form-control form-newsletter" v-model="addMail" style="color:#fff !important" placeholder="Mail">
                        </form>
                    </b-col>
                    
                     <b-col sm="12" style="margin-top:40px;text-align:right">
                        <label class="icon" style="margin-right:20px">Follow Us!</label>
                        <a :href="link_fb" target="_blank">
                            <img src="../assets/fb-icon.png" class="pointer-select" style="width:40px;margin-right:15px" >
                        </a>
                        <a :href="link_ig" target="_blank">
                            <img src="../assets/ig-icon.png" class="pointer-select" style="width:40px;margin-right:15px" >
                        </a>
                        <a :href="link_youtube" target="_blank">
                            <img src="../assets/yt-icon.png" class="pointer-select" style="width:40px;margin-right:15px" >
                        </a>
                     </b-col>
                    
                     <b-col sm="12" style="margin-top:20px;text-align:right">
                        <!-- <label class="icon" style=""></label> -->
                        <a :href="contact_tokped" target="_blank">
                            <img src="../assets/shopee-icon.png" class="pointer-select" style="width:40px;margin-right:15px" >
                        </a>
                        <a :href="contact_tokped" target="_blank">
                            <img src="../assets/tokped-icon.png" class="pointer-select" style="width:40px;margin-right:15px" >
                        </a>
                     </b-col>
                  </b-row>
              </b-col>
            </b-row>
            
          </b-col>
          
        </b-row>
      </b-container>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data() {
      return {
        contact_tokped:'',
        contact_shopee:'',
        link_fb:'',
        link_ig:'',
        link_youtube:'',
        youtube_embed:'',
        addMail:''
      }
    },
    mounted(){
        const vm = this;
        
        Vue.axios({
            method: 'get',
            url: this.$urlApi+'company_info',
            headers: { 'content-type': 'application/json' },
            // data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == 'Success')
            {
                
                vm.link_fb = result.link_fb;
                vm.link_ig = result.link_ig;
                vm.link_youtube = result.link_youtube;
                vm.contact_shopee = result.link_shopee;
                vm.contact_tokped = result.link_tokped;
                vm.youtube_embed = result.youtube_embed;
            }
        });
    },
    methods: {
      sendNewsletter()
      {
        // const vm = this;
        const req_post = {
            email:this.addMail
        }
        Vue.axios({
            method: 'post',
            url: this.$urlApi+'add_newsletter',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: JSON.stringify(req_post),
        }).then(function (response) {
            var result = response.data;
            console.log(result);
            // if(result.error != true)
            if(result.status == true)
            {
                
                alert(result.msg)
            }
            else
            {
                alert(result.msg)
            }
        });
      },
    }
}
</script>
<style >
.header-menu
{
  /* margin-right: 20px;
  margin-left: 20px; */
  /* font-size: 14px; */
  text-decoration: none;
  font-weight: bold;
  color: #fff;
}
</style>